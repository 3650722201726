import './distributiva_atracciones_fl.scss';

import PropTypes from 'prop-types';
import React from 'react';
import TrackVisibility from 'react-on-screen';
import { graphql } from 'gatsby';
import { Asgard } from '../src/components/Asgard/Asgard';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Layout from '../src/components/Layout/Layout';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import Astrid from '../src/components/Astrid/Astrid';
import tt from '../src/helpers/translation';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Buttons from '../src/components/Buttons/Buttons';
import Gerd from '../src/components/Gerd/Gerd';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query dist_shows_navidad($locale: String!) {
    allShowNavidadTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(maxWidth: 1600, maxHeight: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allShowNavidadMobileTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allShowNavidadTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
          description
          text
        }
      }
    }
    allShowNavidadSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allShowNavidadSubBlock(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 160, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          position
          imgsize
          title
          description
          disabled
          summary
          seeMore
          located
          features
          possibleFilters1
          possibleFilters2
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allNavidadPromotedSubBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          ctaText
          cta
          description
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 160, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allShowNavidadBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          tag
          destination_url
          name
        }
      }
    }
    allShowNavidadRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
    allShowNavidadRich(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          richmark
        }
      }
    }
  }
`;

class DistNavidad extends React.Component {
  state = {
    once: false,
    throttleInterval: 150,
    offset: 0,
    partialVisibility: false,
    world: '',
    tipoServicio: '',
    tipoMenu: '',
    initialFilterStateMenu: true,
    initialFilterStateMundo: true,
    showModal: false,
  };

  handleFilter = (name, tag) => {
    if (tag === tt('MUNDO', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, world: name };
      this.setState({ world: name, initialFilterStateMundo: false, filterData: newFilterData });
    } else if (tag === tt('TIPO DE ESPECTÁCULO', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, tipoServicio: name };
      this.setState({
        tipoServicio: name,
        initialFilterStateMenu: false,
        filterData: newFilterData,
      });
    }
  };

  resetFilter = () => {
    const newFilterData = new Object();
    this.setState({
      initialFilterStateMenu: true,
      initialFilterStateMundo: true,
      filterData: newFilterData,
    });
  };

  handleMap = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const heimdallData = {
      name: this.props.data.allShowNavidadTitle.edges[0].node.name,
      subtitle: this.props.data.allShowNavidadTitle.edges[0].node.description.replace(
        /<\/?[^>]+(>|$)/g,
        ''
      ),
      image: {
        alt: this.props.data.allShowNavidadTitleBlock.edges[0].node.alt,
        url: this.props.data.allShowNavidadTitleBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
      imageMobile: {
        alt: this.props.data.allShowNavidadMobileTitleBlock.edges[0].node.alt,
        url: this.props.data.allShowNavidadMobileTitleBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
      map: 'true',
    };

    const buttons = {
      cta: tt('/entradas', this.props.pageContext.locale),
      size: 'alone',
      color: 'orange',
      ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
    };

    const AsgardData = {
      image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/030/original/pap.png',
      text: `${
        this.props.data.allShowNavidadSubBlock &&
        this.props.data.allShowNavidadSubBlock.edges.length
      } ${tt('Espectáculos', this.props.pageContext.locale).toUpperCase()}`,
      filters: [
        {
          tag: tt('MUNDO', this.props.pageContext.locale),
          options: [
            { value: tt('Mediterrània', this.props.pageContext.locale), label: 'Mediterrània' },
            { value: tt('China', this.props.pageContext.locale), label: 'China' },
            { value: tt('Polynesia', this.props.pageContext.locale), label: 'Polynesia' },
            { value: tt('México', this.props.pageContext.locale), label: 'México' },
            {
              value: tt('SésamoAventura', this.props.pageContext.locale),
              label: 'Sesamo Aventura',
            },
            { value: tt('Far West', this.props.pageContext.locale), label: 'Far West' },
          ],
        },
        {
          tag: tt('TIPO DE ESPECTÁCULO', this.props.pageContext.locale),
          options: [
            { value: tt('Musical', this.props.pageContext.locale), label: 'musical' },
            { value: tt('Aventura', this.props.pageContext.locale), label: 'aventura' },
            { value: tt('Danza', this.props.pageContext.locale), label: 'danza' },
            { value: tt('Desfile', this.props.pageContext.locale), label: 'desfile' },
            { value: tt('Acrobático', this.props.pageContext.locale), label: 'acrobatico' },
            { value: tt('Multimedia', this.props.pageContext.locale), label: 'multimedia' },
            { value: tt('Didáctico', this.props.pageContext.locale), label: 'didactico' },
            {
              value: tt('Pasaje Del Terror', this.props.pageContext.locale),
              label: 'pasaje_del_terror',
            },
          ],
        },
      ],
    };

    const freyaDataRides = {
      cards: this.props.data.allShowNavidadSubBlock.edges
        .map((ride) => ({
          image: ride.node.localImage.childImageSharp.fluid,
          imgsize: 'summary',
          title: ride.node.title,
          titleType: 'h3',
          description: ride.node.description,
          disabled: ride.node.disabled,
          summary: ride.node.summary,
          seeMore: ride.node.seeMore,
          located:
            ride.node.located === 'Sesamo Aventura'
              ? 'SésamoAventura'
              : ride.node.located.charAt(0).toUpperCase() + ride.node.located.slice(1),
          features: ride.node.features,
          possibleFilters: ride.node.possibleFilters1 + ride.node.possibleFilters2,
          buttons: {
            cta: ride.node.buttons.cta,
            size: ride.node.buttons.size,
            color: ride.node.buttons.color,
            ctaText: ride.node.buttons.ctaText,
          },
        }))
        .filter((item) => {
          const setFilters = [];
          for (const prop in this.state.filterData) setFilters.push(this.state.filterData[prop]);
          if (!setFilters.length) return item;
          if (setFilters.every((elem) => item.possibleFilters.indexOf(elem) > -1)) return item;
        }),
    };

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: this.props.data.allNavidadPromotedSubBlock.edges.map((shop) => ({
        image: shop.node.localImage.childImageSharp.fluid,
        title: shop.node.description,
        imgsize: 'promoted',
        buttons: {
          cta: shop.node.cta,
          size: 'alone',
          color: 'white',
          ctaText: shop.node.ctaText,
        },
      })),
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allShowNavidadSeoData.edges[0].node._0.title}
          description={this.props.data.allShowNavidadSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} handleMap={this.handleMap} />
          <div className="Asgard-box">
            <Asgard
              locale={this.props.pageContext.locale}
              data={AsgardData}
              filterStatusTipoServicios={this.state.initialFilterStateMenu}
              filterStatusMundo={this.state.initialFilterStateMundo}
              resetFilter={this.resetFilter}
              filterChange={this.handleFilter}
            />
          </div>
          <Astrid
            pageContext={this.props.pageContext}
            handleMap={this.handleMap}
            modal={this.state.showModal}
          />
          <Buttons
            link={buttons.cta}
            size={buttons.size}
            color={buttons.color}
            text={buttons.ctaText}
          />
          <BlueArrow />
          <div className="general-index">
            <div className="rides-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                // title={this.props.data.allShowNavidadBreadCrumbBlock.edges[0].node.name}
              />
              <Hod
                hardcoded={
                  this.props.data.allShowNavidadRich.edges.length &&
                  this.props.data.allShowNavidadRich.edges[0].node.richmark
                }
                data={this.props.data.allShowNavidadRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allShowNavidadTitleBlock.edges[0].node.localImage.childImageSharp
                    .fluid
                }
              />
              <Freya data={freyaDataRides} />
              <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
              <div className="rides-content-promo">
                <H4 title={tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale)} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default DistNavidad;
